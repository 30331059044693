.ytv-rspsv {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

}

@media screen and (max-width: 600px) {
    .ytv-rspsv {
        margin-bottom: 32px;
    }
}

.ytv-rspsv iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}