#cookie-consent-banner {
    box-shadow: 0 1px 10px -4px #0000004a;
}

#cookie-consent-banner .banner-button-container button {
    border-radius: 3px;
    padding: 12px 35px;
}

#cookie-consent-banner .banner-close-icon {
    display:none;
}
