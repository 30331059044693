#iam-login-form .auth0-lock.auth0-lock {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
}


@media (min-width: 481px) {
    #iam-login-form .auth0-lock.auth0-lock .auth0-lock-header-bg {
        background: none !important;
    }

    #iam-login-form .auth0-lock.auth0-lock .auth0-lock-center {
        padding: 16px 8px 32px;

    }

    #iam-login-form .auth0-lock.auth0-lock .auth0-lock-widget {
        min-width: 336px;
    }
}


#iam-login-form .auth0-lock.auth0-lock .auth0-lock-center {
    padding-bottom: 32px;
}

#iam-login-form .auth0-lock.auth0-lock.auth0-lock-opened-in-frame .auth0-lock-header-bg .auth0-lock-header-bg-blur {
    background-image: none !important;
}

#iam-login-form .auth0-lock-header-bg-solid {
    background-color: transparent !important;
}

#iam-login-form button.auth0-lock-submit {
    border-radius: 3px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    margin-top: 8px;
    font-weight: 600;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-terms {
    padding: 8px !important;
    background: #F5F6F8 !important;
    color: #192A3E;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-terms a {
    color: #109CF1;
    text-decoration: underline;
}

#iam-login-form .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
    background: #F5F6F8 !important;
    border: 1px solid #e3e6ed !important;
}


#iam-login-form .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-icon path,
#iam-login-form .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-custom-icon path {
    fill: #8497b3 !important;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-social-button[data-provider^=google].auth0-lock-social-big-button {
    background-color: #fff;
    border: 1px solid #e3e6ed;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-social-button[data-provider^=google] .auth0-lock-social-button-text {
    color: #192A3E !important;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
    color: #192A3E !important;
}

#iam-login-form input.auth0-lock-input::placeholder {
    color: #b2becd;
}


#iam-login-form a.auth0-lock-alternative-link {
    color: #109CF1 !important;
    text-decoration: underline !important;
}


#iam-login-form .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current {
    box-shadow: 0 1px 0 0 #054582;
    cursor: default;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current span {
    color: #054582 !important;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-tabs li a,
#iam-login-form .auth0-lock.auth0-lock .auth0-lock-tabs li span {
    color: #8497b3 !important;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-header {
    color: #054582 !important;
}


#iam-login-form .auth0-lock.auth0-lock .auth0-lock-form p {
    color: #192A3E;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-close-button,
#iam-login-form .auth0-lock.auth0-lock .auth0-lock-back-button {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-close-button svg,
#iam-login-form .auth0-lock.auth0-lock .auth0-lock-back-button svg {
    height: 16px;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-submit span svg.icon-text {
    display: none;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
    padding-left: 0 !important;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-submit[disabled] {
    background-color: #aab8c28c !important;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-header-logo {
    height: 80px;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error {
    background: #ffeeed;
    color: #F7685B;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-success {
    background: #2ed47a;
    /*color: #f5fffa;*/
}

#iam-login-form .auth0-lock.auth0-lock .auth0-global-message {
    border-radius: 3px;
}


#iam-login-form .auth0-lock-cred-pane {
    padding: 24px 32px 40px;
}

#iam-login-form .auth0-lock.auth0-lock .auth0-lock-widget {
    width: 440px;
}

@media (max-width: 481px) {
    #iam-login-form .auth0-lock.auth0-lock .auth0-lock-widget {
        width: auto !important;
    }

    #iam-login-form .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
        height: auto;
    }
}